export default function LdJSON(props) {
  const data = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "Physio WOW",
    url: "https://physiowow.com",
    image: "https://res.cloudinary.com/physio-wow/image/upload/w_100,q_auto,f_auto/static/logos/blue_logo",
    priceRange: "$$",
    sameAs: [
      "https://www.facebook.com/physiowow",
      "https://instagram.com/physiowow",
      "https://www.doctoralia.es/clinicas/physio-wow"
    ],
    telephone: "+34649321719",
    email: "physiowow@gmail.com",
    address: {
      "@type": "PostalAddress",
      streetAddress: "C/ de Balmes, 372, 1r 1a",
      addressRegion: "Barcelona",
      postalCode: "08022",
      addressCountry: "Spain"
    },
    location: [
      {
        "@type": "PostalAddress",
        telephone: "+34649321719",
        streetAddress: "C/ de Balmes, 372, 1r 1a",
        addressRegion: "Barcelona",
        postalCode: "08022",
        addressCountry: "Spain"
      },
      {
        "@type": "PostalAddress",
        telephone: "+34649321719",
        streetAddress: "Passeig de Sevilla, 53",
        addressRegion: "Sant Cugat del Vallés",
        postalCode: "08195",
        addressCountry: "Spain"
      },
      {
        "@type": "PostalAddress",
        telephone: "+34676332960",
        streetAddress: "Carrer de Montjuïc, 1",
        addressRegion: "Sant Joan Despí",
        postalCode: "08970",
        addressCountry: "Spain"
      }
    ]
  }

  return <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
}
